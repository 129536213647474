import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addNotification } from 'shared/app/shell';

import messages from './messages';
import { commonMessages } from 'shared/app/messages';

const CopyLinkCta = ({ color, TagName, visualStyle, className, copyLink }) => {
  const dispatch = useDispatch();
  const originalMessage = <FormattedMessage {...messages.copyLinkCta} />;
  const messageOnClick = <FormattedMessage {...commonMessages.copied} />;

  const [ctaMessage, setCtaMessage] = useState(originalMessage);

  const changeMessage = () => {
    setCtaMessage(messageOnClick);

    setTimeout(() => {
      setCtaMessage(originalMessage);
    }, 3000);
  };

  return (
    <TagName
      className={className}
      color={color}
      onClick={() => {
        navigator.clipboard.writeText(copyLink);
        changeMessage();
        dispatch(
          addNotification(null, {
            messageId: 'shared.commonWords.linkCopied',
            autoClose: 3000,
          })
        );
      }}
      visualStyle={visualStyle}
    >
      {ctaMessage}
    </TagName>
  );
};

export default CopyLinkCta;
