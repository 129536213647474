import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { showErrorNotification } from 'shared/app/shell';
import { getDeviceFingerprint } from 'shared/app/utils/iovation';

import {
  localeTagSelector,
  securePaymentFormUrlSelector,
} from 'shared/app/state/selectors/locales';

import {
  IFRAME_HEIGHT_CHANGED,
  PAYMENT_CANCELED,
  PAYMENT_SAVED,
  PAYMENT_FAILED,
  PAYMENT_VALIDATION_FAILED,
  DEVICE_FINGERPRINT_RECEIVED,
} from './post-message-actions';

import messages from './messages';

const IFRAME_ID = 'spf-iframe';

const noop = () => {
  /* no operation */
};

export const sendPostMessage = (spfUrl, message) => {
  const elIframe = document.getElementById(IFRAME_ID);
  if (!elIframe) {
    return;
  }
  elIframe.contentWindow.postMessage(message, spfUrl);
};

// eslint-disable-next-line max-statements
export const SecurePaymentFormIframe = ({
  mode,
  // addressId is long-lived address for an individual user.
  addressId,
  // addressToken is a single use billing address token for guest users.
  addressToken,
  initialHeight = 580,
  paymentInstrumentId,
  // totalAmount of the guest transaction
  totalAmount,
  onIframeLoadHandler = noop,
  onSave = noop,
  onCancel = noop,
}) => {
  const dispatch = useDispatch();
  const securePaymentFormUrl = useSelector(securePaymentFormUrlSelector);
  const locale = useSelector(localeTagSelector);
  const iframeParams = new URLSearchParams({ locale, mode });
  if (addressId) {
    iframeParams.append('addressId', addressId);
  }
  if (addressToken) {
    iframeParams.append('addressToken', addressToken);
  }
  if (paymentInstrumentId) {
    iframeParams.append('paymentInstrumentId', paymentInstrumentId);
  }
  if (totalAmount) {
    iframeParams.append('totalAmount', totalAmount);
  }
  const url = `${securePaymentFormUrl}?${iframeParams.toString()}`;

  const [iframeHeight, setIframeHeight] = useState(initialHeight);

  const onReceivePostMessage = (event) => {
    if (event.origin !== securePaymentFormUrl) {
      return;
    }

    const { action } = event.data;

    if (action === IFRAME_HEIGHT_CHANGED) {
      setIframeHeight(event.data.height);
    }

    if (action === PAYMENT_VALIDATION_FAILED) {
      dispatch(
        showErrorNotification({
          messageId: messages.creditCardError.id,
        })
      );
    }

    if (action === PAYMENT_FAILED) {
      dispatch(
        showErrorNotification({
          messageId: messages.UnknownSystemError.id,
          callback: () => onCancel(),
        })
      );
    }

    if (action === PAYMENT_SAVED) {
      onSave();
    }

    if (action === PAYMENT_CANCELED) {
      onCancel();
    }
  };

  const onIframeLoad = async () => {
    await getDeviceFingerprint().then((deviceFingerprint) => {
      const message = {
        action: DEVICE_FINGERPRINT_RECEIVED,
        deviceFingerprint,
      };
      sendPostMessage(securePaymentFormUrl, message);
    });
    onIframeLoadHandler();
  };

  useEffect(() => {
    window.addEventListener('message', onReceivePostMessage, false);
    document
      .getElementById(IFRAME_ID)
      .addEventListener('load', onIframeLoad, false);
    return () => {
      window.removeEventListener('message', onReceivePostMessage);
      const elIframe = document.getElementById(IFRAME_ID);
      if (elIframe) {
        elIframe.removeEventListener('load', onIframeLoad);
      }
    };
  }, []);

  const { formatMessage } = useIntl();

  return (
    <iframe
      id={IFRAME_ID}
      scrolling="no"
      src={url}
      style={{
        height: `${iframeHeight}px`,
        width: '100%',
      }}
      title={formatMessage(messages.iframeTitle)}
    />
  );
};

SecurePaymentFormIframe.propTypes = {
  initialHeight: PropTypes.number,
  addressId: PropTypes.string,
  addressToken: PropTypes.string,
  paymentInstrumentId: PropTypes.string,
  totalAmount: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  // guestV1 is the current guest SPF used in Gift GCO
  // a new guestV2 will be introduced soon and will be used in MOP
  mode: PropTypes.oneOf(['guestV1', 'guestV2', 'add', 'edit']).isRequired,
};

export default SecurePaymentFormIframe;
