import { pickBy } from 'lodash';

import { sharedCallsToAction } from 'shared/app/messages';

export const getLocalizedText = (notification, formatMessage) => {
  const defaultCtas = {
    cancelText: formatMessage(sharedCallsToAction.cancel),
    confirmText: formatMessage(sharedCallsToAction.gotIt),
  };

  const userDefinedCtas = pickBy({
    cancelText:
      notification?.cancelTextId &&
      formatMessage(
        { id: notification.cancelTextId },
        notification.cancelTextValues
      ),

    confirmText:
      notification?.confirmTextId &&
      formatMessage(
        { id: notification.confirmTextId },
        notification.confirmTextValues
      ),
  });

  const body =
    !notification?.body && notification?.messageId
      ? formatMessage(
          { id: notification.messageId },
          notification.messageValues
        )
      : notification?.body ?? '';

  const title =
    !notification?.title && notification?.titleId
      ? formatMessage({ id: notification.titleId }, notification.titleValues)
      : notification?.title ?? '';

  return { body, title, ...defaultCtas, ...userDefinedCtas };
};
