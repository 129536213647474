import React from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@starbucks-web/pattern-library/lib/components/card';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import { commonMessages } from 'shared/app/messages/common-words';
import FormattedMoney from '../../formatted-money';

import PurchaseStatus from './purchase-status';
import SummaryRecipients from './recipients';

import receiptMessages from '../messages';
import messages from './messages';

const GiftSummary = ({
  altText,
  giftPurchaseDetails: {
    amount,
    message,
    numberOfRecipients,
    orderNumber,
    purchaseStatus,
    recipients,
    totalAmount,
    largeImageUrl,
  },
  onClick,
}) => {
  return (
    <React.Fragment>
      <Heading
        className="text-semibold text-center flex flex-column mb5"
        tagName="h2"
      >
        <span className="color-textBlackSoft mb1">
          <FormattedMessage {...messages.summaryHeading} />
        </span>
        <span className="text-lg">
          <FormattedMessage {...commonMessages.total} />{' '}
          <FormattedMoney value={totalAmount} />
        </span>
      </Heading>
      <Card containerClasses="md-mt2 mb5 lg-mb6 py2 px3 lg-px4">
        <PurchaseStatus
          altText={altText}
          amount={amount}
          largeImageUrl={largeImageUrl}
          message={message}
          numberOfRecipients={numberOfRecipients}
          onClick={onClick}
          purchaseStatus={purchaseStatus}
          recipients={recipients}
        />
        <SummaryRecipients
          amount={amount}
          numberOfRecipients={numberOfRecipients}
          recipients={recipients}
        />
        <p className="mt3 text-right text-upper text-bold color-textBlackSoft">
          <FormattedMessage
            {...receiptMessages.orderNumber}
            values={{ orderNumber: orderNumber || false }}
          />
        </p>
      </Card>
    </React.Fragment>
  );
};

export default GiftSummary;
